import React,{useState} from 'react';
import clsx from 'clsx';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { useHistory } from "react-router-dom";
import {
  Box,
  Button,
  FormHelperText,
  CardContent,
  TextField,
  Typography,
  Card,
  Container,
  Divider,
  Grid,
  Link,
  CardHeader,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import { Alert } from '@material-ui/lab';
import useAuth from 'src/hooks/useAuth';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import { Link as RouterLink } from 'react-router-dom';
import axios from 'axios';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    flexDirection: 'column',

  },
  banner: {
    backgroundColor: theme.palette.background.paper,
   
    //paddingTop: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  bannerChip: {
    marginRight: theme.spacing(2)
  },
  methodIcon: {
    height: 30,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  },
  cardContainer: {
    paddingBottom: 20,
    paddingTop: 40,
  },
  cardContent: {
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    //minHeight: 400
  },
  currentMethodIcon: {
    height: 100,
      width: 100,
     
    avatar: {
      height: 100,
      width: 100
    }
  }
}));

const Change_Password = ({ className,open, ...rest }) => {
  const classes = useStyles();
  const { confirm_forgot_password } = useAuth();
  const { forgot_password } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const isMountedRef = useIsMountedRef();
  const history = useHistory();

  const [error,setError] = useState()
console.log(error)
  return (
    <Formik
      initialValues={{
        current_password : '',
        password : '',
        passwordConfirm : ''
      }}
      validationSchema={Yup.object().shape({
       
        current_password: Yup.string()
          .required('Required'),
        password: Yup.string()

          .max(255)
          .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
            "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
          )
          .required('Required'),
        passwordConfirm: Yup.string()
          .oneOf([Yup.ref('password'), null], 'Passwords must match')
          .required('Required')
      
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          console.log(values);
          
          try {
          let response;
          const payload = {
        
            current_password: values.current_password,
            new_password: values.password,
            
          };
    
          if (process.env.REACT_APP_ENV === 'test') {
            response = await axios.post(
              'https://jwa0stlj67.execute-api.ap-south-1.amazonaws.com/test/resetPassword',
              payload
            );
            console.log(response)
            open()
          } else {
            response = await axios.post(
              'https://jwa0stlj67.execute-api.ap-south-1.amazonaws.com/prod/resetPassword',
              payload
            )
            console.log(response)
            open()
          }
          console.log(response)
          if(response.data.message === 'success'){
           
            enqueueSnackbar('Password updated', {
              variant: 'success'
            });
           
          }
        } catch (err) {
          console.log(err.response);
          setError(err.response)
        }

          if (isMountedRef.current) {
            setStatus({ success: true });
            setSubmitting(false);
          }
        } catch (err) {
          console.error(err);
          if (isMountedRef.current) {
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
          }
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }) => (
        <form
          noValidate
          onSubmit={handleSubmit}
          className={clsx(classes.root, className)}
          {...rest}
  
        >
            <Page
      className={classes.root}
      title="Forgot Password"
    >
      
     
        <Box
        
          display="flex"
          justifyContent="center"
        >
         
        </Box>
        <Card>
        <CardHeader title = "Reset Password" />
        <Divider />
          <CardContent className={classes.cardContent}>
            {/* <Box
              alignItems="center"
              display="flex"
              justifyContent="space-between"
              mb={2}
            >
              <div>
                <Typography
                  color="textPrimary"
                  gutterBottom
                  variant="h2"
                >
                  Change Password
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                >
                  Please enter the Current Password and New Password
                </Typography>
              </div>
              <div className={classes.currentMethodIcon}>
               
              </div>
            </Box> */}

          <TextField
            error={Boolean(touched.current_password && errors.current_password)}
            fullWidth
            helperText={touched.current_password && errors.current_password}
            label="Current Password"
            margin="normal"
            name="current_password"
            onBlur={handleBlur}
            onChange={handleChange}
            type="password"
            value={values.current_password}
            variant="outlined"
          />
<Box mt = {1} />
          <TextField
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            label="New Password"
            name="password"
            autoComplete="new-password"
            onBlur={handleBlur}
            onChange={handleChange}
            type="password"
            value={values.password}
            variant="outlined"
          />
          <Box mt = {2} />
          <TextField
            error={Boolean(
              touched.passwordConfirm && errors.passwordConfirm
            )}
            fullWidth
            helperText={
              touched.passwordConfirm && errors.passwordConfirm
            }
            label="New Password Confirmation"
            name="passwordConfirm"
            onBlur={handleBlur}
            onChange={handleChange}
            type="password"
            value={values.passwordConfirm}
            variant="outlined"
          />
        
          {/* {errors.submit && (
            <Box mt={3}>
              <FormHelperText error>{errors.submit}</FormHelperText>
            </Box>
          )} */}
          
           {/* {error && error.data.message === "Invalid verification code provided, please try again." && (
             <Box display = "flex">
               <Box flexGrow = {1} />
               <Button
               onClick = {forgot_password(useremail)}
               >
                 Resend OTP
               </Button>
             </Box>
           )} */}
            {errors.submit && (
            <Box mt={3}>
              <FormHelperText error>
                <Box mt={2}>
                  <Alert severity="info">
                    <div>{errors.submit}</div>
                  </Alert>
                </Box>
              </FormHelperText>
            </Box>
          )}
           
           {error && (
            <Box mt={2}>
              <Alert severity="info">
                <div>{error.data.message}</div>
              </Alert>
            </Box>
          )}
          <Box display = "flex">
            <Box flexGrow = {1} />
          <Box mt={2}>
            <Button
              color="secondary"
              disabled={isSubmitting}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              Submit
            </Button>
          </Box>
          </Box>
         
          
           
         
         
          </CardContent>
        </Card>
      
    </Page>
         
          
        </form>
       
      )}
    </Formik>
  );
};

Change_Password.propTypes = {
  className: PropTypes.string
};

export default Change_Password;